// Import components
import PrimaryDescription from "./PrimaryDescription/PrimaryDescription";
import CardDescription from "./CardDescription/CardDescription";

// Import styles
import "./home.css";

const Home = () => {
    const isMobile = window.screen.width;
    return (
        <>
            {
                (isMobile < 600) ?
                    <>
                        <PrimaryDescription />
                        <div className="videoIllustrationSection">
                            <img
                                className="illustration"
                                src="https://021.trade/assets/gifs/bull-bear.gif"
                                alt="illustration"
                            />
                        </div>
                        <br />
                        <CardDescription />
                        <br />
                        <br />
                    </>
                    :
                    <div className="generalLayout">
                        <div className="contentSection">
                            <PrimaryDescription />
                            <CardDescription />
                        </div>
                        <div className="videoIllustrationSection">
                            <img
                                className="illustration"
                                src="https://021.trade/assets/gifs/bull-bear.gif"
                                alt="illustration"
                            />
                        </div>
                    </div>
            }
        </>
    )
}

export default Home;