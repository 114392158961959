// Import React hooks
import { useState } from "react";

// Import antd components
import { Modal, Input, Space } from "antd";

// Import Call API
import CallAPI from "CallAPI";

import { BASE_URL } from "constants";

const ContactForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serverResponse, setServerResponse] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setServerResponse("");
    };

    let [contactDetail, setContactDetail] = useState();

    const handleContactDetailChange = (event) => {
        setContactDetail(event.target.value);
    }

    let data = {
        userId: localStorage.getItem('userId')
    }

    const createUserEntry = async () => {
        if (contactDetail?.includes("@")) {
            data.email = contactDetail;
            const response = await CallAPI("POST", BASE_URL + "/users/updateContactInfo", {}, data);
            if (response.result && response.result.message) {
                setServerResponse(response.result.message);
            }
        }
        else {
            data.mobile = parseInt(contactDetail);
            const response = await CallAPI("POST", BASE_URL + "/users/updateContactInfo", {}, data);

            if (response.result && response.result.message) {
                setServerResponse(response.result.message);
            }
        }
    }

    const updateWhatsappAccessCount = async () => {
        CallAPI("POST", BASE_URL + "/users/updateWhatsappAccess", {}, data);
    }

    return (
        <>
            <Space.Compact className="inputArea">
                <Input
                    className="contactInput"
                    placeholder="Email/Mobile"
                    onChange={handleContactDetailChange}
                />
                <button onClick={() => { showModal(); createUserEntry() }} className="modalButton">
                    Get in touch!
                </button>
            </Space.Compact>
            <p className="disclaimer">
                *We hate spam as much as you do, so there you have your no spam promise!
                <br />
                &nbsp; This is an absolutely free, no obligation service to build awareness amongst investors.
            </p>
            {
                (serverResponse) ?
                    <Modal
                        open={isModalOpen}
                        className="acknowledgementModal"
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        centered={true}
                    >
                        <p>{serverResponse}</p>
                        <p>If you want to connect with us immediately then</p>
                        <a
                            href="https://wa.me/919987534644?text=Hi%2C%20I%20have%20a%20query%20on%20the%20stock%20market."
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button
                                className="contactButton"
                                onClick={updateWhatsappAccessCount}
                            >
                                Text us on WhatsApp!
                            </button>
                        </a>
                    </Modal>
                    :
                    <></>
            }
        </>
    )
}

export default ContactForm;
