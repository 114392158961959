// Import React and its hooks
import { useEffect, useState } from "react";

// Import styles
import "draft-js/dist/Draft.css";

// Import TextEditor
import TextEditor from "./TextEditor";

// Import CallAPI
import CallAPI from "CallAPI";
import { BASE_URL } from "constants";

const CreatePost = () => {
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const validatePasswordForAccess = async () => {
        const headers = {
            password: window.location.pathname.split("/")[1]
        };
        // const password = window.location.pathname.split("/")[1]

        const response = await CallAPI("POST", BASE_URL + `/posts/validatePassword`, headers, {});
        // const response = await CallAPI("POST", BASE_URL + `/posts/validatePassword/${password}`, {}, {});
        if (response.result && response.result.status) {
            setIsPasswordValid(response.result.message);
        }
    }

    useEffect(() => {
        validatePasswordForAccess();
    }, []);

    return (
        <>
            {
                isPasswordValid ? <TextEditor /> : <h1>You are unauthorized to visit this page</h1>
            }
        </>
    )
}

export default CreatePost;