// Import React and hooks
import React from "react";

// Import Base URL
import { BASE_URL } from "constants";

// Import Call API common function
import CallAPI from "CallAPI";

// Import draft-js
import { EditorState, AtomicBlockUtils, RichUtils, getDefaultKeyBinding, convertToRaw, convertFromRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createImagePlugin from "@draft-js-plugins/image";
import createAlignmentPlugin from "@draft-js-plugins/alignment";
import createFocusPlugin from "@draft-js-plugins/focus";
import createResizablePlugin from "@draft-js-plugins/resizeable";

// Import styles
import "./textEditor.css";
import "./Draft.css";

const imagePlugin = createImagePlugin();
const alignmentPlugin = createAlignmentPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizablePlugin();

class TextEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = { editorState: EditorState.createEmpty(), imageUrl: "", imageBase64: "", postid: "" };
		this.fileInputRef = React.createRef();

		this.focus = () => this.refs.editor.focus();
		this.onChange = (editorState) => {
			this.setState({ editorState })
		};

		this.handleKeyCommand = this._handleKeyCommand.bind(this);
		this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
		this.toggleBlockType = this._toggleBlockType.bind(this);
		this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
	}

	componentDidMount = async () => {
		let currentURL = window.location.pathname;
		let postTitleParameter = currentURL.split("/")[2];

		if (postTitleParameter !== undefined) {
			const response = await CallAPI("GET", BASE_URL + `/posts/getPostContent/${postTitleParameter}`, {});
			if (response.result && response.result.post) {
				this.setState({
					editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response.result.post.content))),
					postid: response.result.post._id
				})
			}
			else {
				this.setState({ editorState: EditorState.createEmpty() })
			}
		}
		else {
			this.setState({ editorState: EditorState.createEmpty() })
		}
	}

	_handleKeyCommand(command, editorState) {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	}

	_mapKeyToEditorCommand(e) {
		if (e.keyCode === 9 /* TAB */) {
			const newEditorState = RichUtils.onTab(
				e,
				this.state.editorState,
				4, /* maxDepth */
			);
			if (newEditorState !== this.state.editorState) {
				this.onChange(newEditorState);
			}
			return;
		}
		return getDefaultKeyBinding(e);
	}

	_toggleBlockType(blockType) {
		this.onChange(
			RichUtils.toggleBlockType(
				this.state.editorState,
				blockType
			)
		);
	}

	_toggleInlineStyle(inlineStyle) {
		this.onChange(
			RichUtils.toggleInlineStyle(
				this.state.editorState,
				inlineStyle
			)
		);
	}

	saveContent = async () => {
		const contentState = this.state.editorState.getCurrentContent();
		const rawMetaData = convertToRaw(contentState);

		const data = {
			title: rawMetaData.blocks[0].text.replaceAll(" ", "-").toLowerCase(),
			postid: this.state.postid,
			content: JSON.stringify(convertToRaw(contentState))
		}

		const headers = {
			password: window.location.pathname.split("/")[1]
		}

		const response = await CallAPI("POST", BASE_URL + "/posts/savePostContent", headers, data);

		if (response.result && response.result.postid) {
			this.setState({ postid: response.result.postid });
			alert(response.result.message);
		}
	}

	publishContent = async () => {
		const contentState = this.state.editorState.getCurrentContent();
		const rawMetaData = convertToRaw(contentState);

		const data = {
			title: rawMetaData.blocks[0].text.replaceAll(" ", "-").toLowerCase(),
			postid: this.state.postid,
			content: JSON.stringify(convertToRaw(contentState))
		}

		const headers = {
			password: window.location.pathname.split("/")[1]
		}

		const response = await CallAPI("POST", BASE_URL + "/posts/publishPostContent", headers, data);

		if (response.result && response.result.postid) {
			this.setState({ postid: response.result.postid });
			alert(response.result.message);
		}
	}

	handlePastedFiles = async (event) => {
		event.preventDefault();
		let data = new FormData();
		data.append('image', this.state.imageUrl);

		const headers = {
			password: window.location.pathname.split("/")[1],
			'Content-Type': 'multipart/form-data'
		}

		const response = await CallAPI("POST", BASE_URL + "/posts/uploadImage", headers, data);

		if (response.result && response.result.path) {
			this.setState({
				editorState: this.insertImage(`https://021.trade/${response.result.path}`)
				// editorState: this.insertImage("https://images.unsplash.com/photo-1579353977828-2a4eab540b9a?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
			})
		}
		
	};

	insertImage = (url) => {
		console.log(url);
		const { editorState } = this.state;
		const currentContent = editorState.getCurrentContent();
		const contentStateWithEntity = currentContent.createEntity(
			'IMAGE',
			'IMMUTABLE',
			{ src: url }
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
		return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
	};

	render() {
		const { editorState } = this.state;
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
			<>
				<div className="RichEditor-root">
					<BlockStyleControls
						editorState={editorState}
						onToggle={this.toggleBlockType}
					/>
					<InlineStyleControls
						editorState={editorState}
						onToggle={this.toggleInlineStyle}
					/>
					<form encType = "multipart/form">
						<input
							type="file"
							accept="image/*"
							onChange={(event) => { this.setState({ imageUrl: event.target.files[0] }) }}
						/>
						<button onClick={this.handlePastedFiles}>Insert Image</button>
					</form>
					<br /><br /><br />
					<div className={className} onClick={this.focus}>
						<Editor
							blockStyleFn={getBlockStyle}
							customStyleMap="styleMap"
							editorState={editorState}
							handleKeyCommand={this.handleKeyCommand}
							handlePastedFiles={this.handlePastedFiles}
							keyBindingFn={this.mapKeyToEditorCommand}
							onChange={this.onChange}
							plugins={[imagePlugin, alignmentPlugin, focusPlugin, resizeablePlugin]}
							placeholder="Start writing..."
							ref="editor"
							spellCheck={true}
						/>
					</div>
				</div>
				<button onClick={this.saveContent}>
					SAVE
				</button>
				<button onClick={this.publishContent}>
					PUBLISH
				</button>
			</>
		);
	}
}

// Custom overrides for "code" style.
// const styleMap = {
//   CODE: {
//     backgroundColor: 'rgba(0, 0, 0, 0.05)',
//     fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
//     fontSize: 16,
//     padding: 2,
//   },
// };

function getBlockStyle(block) {
	switch (block.getType()) {
		case 'blockquote': return 'RichEditor-blockquote';
		default: return null;
	}
}

class StyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		let className = 'RichEditor-styleButton';
		if (this.props.active) {
			className += ' RichEditor-activeButton';
		}

		return (
			<span className={className} onMouseDown={this.onToggle}>
				{this.props.label}
			</span>
		);
	}
}

const BLOCK_TYPES = [
	{ label: 'H1', style: 'header-one' },
	{ label: 'H2', style: 'header-two' },
	{ label: 'H3', style: 'header-three' },
	{ label: 'H4', style: 'header-four' },
	{ label: 'H5', style: 'header-five' },
	{ label: 'H6', style: 'header-six' },
	{ label: 'Blockquote', style: 'blockquote' },
	{ label: 'UL', style: 'unordered-list-item' },
	{ label: 'OL', style: 'ordered-list-item' },
	{ label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
	const { editorState } = props;
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) =>
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
};

var INLINE_STYLES = [
	{ label: 'Bold', style: 'BOLD' },
	{ label: 'Italic', style: 'ITALIC' },
	{ label: 'Underline', style: 'UNDERLINE' },
	{ label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
	const currentStyle = props.editorState.getCurrentInlineStyle();

	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map((type) =>
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
};
export default TextEditor;