// Import antd components
import { Row } from "antd";

// Import router
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    const redirectToHomePage = () => {
        navigate("/");
    }

    const redirectToPostsPage = () => {
        navigate("/posts");
    }

    return (
        <>
            <Row className = "mainHeader">
                <h2 className = "mainHeaderTitle" onClick = {redirectToHomePage}>021 Trade</h2> 
                <h2 className = "postHeaderContent"onClick = {redirectToPostsPage}>Posts</h2>
            </Row>
        </>
    )
}

export default Header;