import axios from "axios";

export default async function CallAPI (method, url, headers, data) {
    try {
        let options = {};
        if (method === "POST" || method === "PUT") {
            options = {
                method: method,
                url: url,
                withCredentials: true,
                // headers: {
                //     authorization: headers.authorization
                // },
                headers: headers,
                data: data
            }
        }
        else {
            options = {
                method: method,
                url: url,
                withCredentials: true,
                // headers: {
                //     authorization: headers.authorization
                // }
                headers: headers
            }
        }

        let result;

        await axios.request(options)
            .then((response) => {
                result = {
                    responseCode: 200,
                    result: response.data
                }
            })
            .catch((error) => {
                try {
                    result = {
                        responseCode: error.response.status,
                        result: error.response.data
                    };
                }
                catch (err) {
                    result = err;
                }
            });

        return result;
    } catch (error) {
        return error;
    }
}