// Import antd components
import { Col } from "antd";


const PrimaryDescription = () => {
    return (
        <Col className = "primaryDescriptionContainer">
            <p className = "primaryDescriptionText">
                We're building India's most reliable <span className = "tradingPlatform">Trading Platform</span>
            </p>
        </Col>
    )
}

export default PrimaryDescription;