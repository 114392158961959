// Import React and hooks
import React from "react";

// Import components
import {
	Header,
	Footer,
	Loader
} from "Layouts";

// Import Antd components
import { Row, Col, Input, Modal } from "antd";

// Import Call API
import CallAPI from "CallAPI";

// Import Base URL
import { BASE_URL } from "constants";

// Import draft-js
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createImagePlugin from "@draft-js-plugins/image";
import createAlignmentPlugin from "@draft-js-plugins/alignment";
import createFocusPlugin from "@draft-js-plugins/focus";
import createResizablePlugin from "@draft-js-plugins/resizeable";

// Import styles
import "./Draft.css";

const imagePlugin = createImagePlugin();
const alignmentPlugin = createAlignmentPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizablePlugin();

class PostView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			imageUrl: "",
			imageBase64: "",
			blogid: "",
			reactions: {},
			email: "",
			isModalOpen: false,
			newsletterSubscriptionResponse: ""
		};
		this.fileInputRef = React.createRef();

		this.focus = () => this.refs.editor.focus();
		this.onChange = (editorState) => {
			this.setState({ editorState })
		};
	}

	componentDidMount = async () => {

		const ogTags = {
			"og:title": `${window.location.pathname.split("/")[2].replaceAll("-", " ").replace(/^./, str => str.toUpperCase())}`,
			"og:url": `https://021.trade${window.location.pathname}`,
			"og:type": "article"
		}

		const metaTags = document.head.querySelectorAll('meta');
		metaTags.forEach((tag) => {
			document.head.removeChild(tag);
			const property = tag.getAttribute('property');

			if (ogTags[property]) {
				tag.setAttribute("content", ogTags[property]);
			}

			document.head.insertBefore(tag, document.head.firstChild);
		})

		window.scrollTo(0, 0);
		let currentURL = window.location.pathname;
		let postTitleParameter = currentURL.split("/")[2];

		const data = {
			userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : ""
		}

		if (postTitleParameter !== undefined) {
			CallAPI("POST", BASE_URL + `/posts/updatePostViewsCount/${postTitleParameter}`, {}, data);

			const response = await CallAPI("GET", BASE_URL + `/posts/getPostContent/${postTitleParameter}`, {});
			if (response.result && response.result.post) {
				this.setState({
					editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response.result.post.content))),
					blogid: response.result.post._id,
					reactions: response.result.post.reactions
				})
			}
			else {
				this.setState({ editorState: EditorState.createEmpty() })
			}
		}
		else {
			this.setState({ editorState: EditorState.createEmpty() })
		}
	}

	updatePostReactions = (reactionType) => {
		let currentURL = window.location.pathname;
		let postTitleParameter = currentURL.split("/")[2];

		const data = {
			reactionType: reactionType
		}

		CallAPI("POST", BASE_URL + `/posts/updatePostreactions/${postTitleParameter}`, {}, data);
		this.setState({
			reactions: {
				...this.state.reactions,
				[reactionType]: this.state.reactions[reactionType] + 1
			}
		})
	}

	updateNewsLetterSubscription = async () => {
		if (this.state.email === "" || !this.state.email.includes(".") || !this.state.email.includes("@")) {
			this.setState({
				newsletterSubscriptionResponse: "Enter a valid email address!"
			})
		}
		else {
			let currentURL = window.location.pathname;
			let postTitleParameter = currentURL.split("/")[2];

			const data = {
				email: this.state.email,
				userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : ""
			}

			const response = await CallAPI("POST", BASE_URL + `/users/userNewsLetterSubscription/${postTitleParameter}`, {}, data);

			if (response.result && response.result.message) {
				this.setState({
					newsletterSubscriptionResponse: response.result.message
				})
			}
		}
	}

	showModal = () => {
		this.setState({
			isModalOpen: true
		});
	};
	handleOk = () => {
		this.setState({
			isModalOpen: false,
			newsletterSubscriptionResponse: ""
		})
	};
	handleCancel = () => {
		this.setState({
			isModalOpen: false,
			newsletterSubscriptionResponse: ""
		})
	};

	render() {
		const { editorState } = this.state;
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
			<>
				<Header />
				<div className="RichEditor-root">
					{
						(!this.state.blogid) ?
							<Loader />
							:
							<div className={className} onClick={this.focus}>
								<Editor
									blockStyleFn={getBlockStyle}
									customStyleMap="styleMap"
									editorState={editorState}
									handleKeyCommand={this.handleKeyCommand}
									handlePastedFiles={this.handlePastedFiles}
									keyBindingFn={this.mapKeyToEditorCommand}
									onChange={this.onChange}
									plugins={[imagePlugin, alignmentPlugin, focusPlugin, resizeablePlugin]}
									placeholder="Start writing..."
									ref="editor"
									readOnly={true}
									spellCheck={true}
								/>
							</div>
					}
					<Row className="postArticleDialogSection">
						<Col span={11} className="reactionsDialog">
							<h3>Reactions</h3>
							<Row>
								<Col className="reactionsTab" onClick={() => this.updatePostReactions('&#x1F44D;&#x1F3FB;')}>
									<h4>&#x1F44D;&#x1F3FB; {this.state.reactions['&#x1F44D;&#x1F3FB;']}</h4>
								</Col>
								<Col className="reactionsTab" onClick={() => this.updatePostReactions("&#x1F44E;&#x1F3FB;")}>
									<h4>&#x1F44E;&#x1F3FB;</h4>
								</Col>
							</Row>
						</Col>
						<Col span={13}>
							<div className="newsletterSubscribeDialog">
								<h3>Subscribe to our newsletter!</h3>
								<p>Never miss an update from us. Subscribe to our newsletter!</p>
								<Input
									placeholder="Enter your Email ID"
									onChange={(event) => this.setState({ email: event.target.value })}
								/>
								<button
									onClick={() => { this.updateNewsLetterSubscription(); this.showModal() }}
									className="subscriptionButton"
								>
									Subscribe
								</button>
								{
									(this.state.newsletterSubscriptionResponse) ?
										<Modal
											open={this.state.isModalOpen}
											onOk={this.handleOk}
											onCancel={this.handleCancel}
											footer={null}
											centered={true}
										>
											<p>{this.state.newsletterSubscriptionResponse}</p>
										</Modal>
										:
										<></>
								}
							</div>
						</Col>
					</Row>
				</div>
				<Footer />
			</>
		);
	}
}

function getBlockStyle(block) {
	switch (block.getType()) {
		case 'blockquote': return 'RichEditor-blockquote';
		default: return null;
	}
}

export default PostView;