// Import React hooks
import { useState, useEffect } from "react";

// Import Router
import { useNavigate } from "react-router-dom";

// Import components
import {
    Header,
    Footer,
    Loader
} from "Layouts";

// Import Base URL
import { BASE_URL } from "constants";

// Import Antd components
import { Col, Row, Card, Button } from "antd";

// Import Call API
import CallAPI from "CallAPI";

// Import react-icons and AntD Icons
import { FaSortAmountUpAlt, FaSortAmountDown } from "react-icons/fa";

import "./posts.css";

const PostsPage = () => {
    const navigate = useNavigate();

    const [sortBy, setSortBy] = useState({
        date: false,
        views: false
    })
    const [sortedPosts, setSortedPosts] = useState();

    const getAllPublishedPosts = async () => {
        const response = await CallAPI("GET", BASE_URL + "/posts/getPosts", {});
        if (response.result && response.result.posts) {
            setSortedPosts(response.result.posts);
        }
    }

    useEffect(() => {
        getAllPublishedPosts();
    }, [])

    useEffect(() => {
        const ogTags = {
            "og:title": "021 Market Musings & Updates",
            "og:url": `https://021.trade${window.location.pathname}`,
            "og:image": "https://021.trade/assets/icons/og-image.jpg",
            "og:type": "blog"
        }

        const metaTags = document.head.querySelectorAll('meta');
        metaTags.forEach((tag) => {
            document.head.removeChild(tag);
            const property = tag.getAttribute('property');

            if (ogTags[property]) {
                tag.setAttribute("content", ogTags[property]);
            }

            document.head.insertBefore(tag, document.head.firstChild);
        })
    }, []);

    const redirectToReadPostPage = async (postTitle) => {
        navigate(`/posts/${postTitle}`);
    }

    const sortHandler = (sortParameter) => {
        setSortBy({
            [sortParameter]: !sortBy[sortParameter]
        })
        setSortedPosts(
            sortedPosts.sort((a, b) => {
                return (!sortBy[sortParameter]) ?
                    new Date(b[sortParameter === "date" ? "created" : "views"]) - new Date(a[sortParameter === "date" ? "created" : "views"])
                    :
                    new Date(a[sortParameter === "date" ? "created" : "views"]) - new Date(b[sortParameter === "date" ? "created" : "views"])
            })
        )
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return (
        <>
            <Header />
            <div className="blogsDisplayDiv">
                <button
                    onClick={() => { sortHandler("date") }}
                    className={!sortBy.date ? "unsetSorting" : "setSorting"}
                >
                    {!sortBy.date ? <><FaSortAmountUpAlt /> DATE</> : <><FaSortAmountDown /> DATE</>}
                </button>
                <button
                    onClick={() => { sortHandler("views") }}
                    className={!sortBy.views ? "unsetSorting" : "setSorting"}
                >
                    {!sortBy.views ? <><FaSortAmountUpAlt /> VIEWS</> : <><FaSortAmountDown /> VIEWS</>}
                </button>
            </div>
            {
                (!sortedPosts) ?
                    <Loader />
                    :
                    <div className="blogsDisplayDiv">
                        {
                            sortedPosts.map((singleBlog) => {
                                return (
                                    <Card size="small" key={singleBlog._id} className="blogCard">
                                        <Row>
                                            <Col span={6}>
                                                <img className="blogCardImage" src={JSON.parse(singleBlog.content).entityMap[0].data.src} alt="" />
                                            </Col>
                                            <Col span={15}>
                                                <h3 className="blogTitle">{JSON.parse(singleBlog.content).blocks[0].text}</h3>
                                                <p className="blogDescription">
                                                    {
                                                        JSON.parse(singleBlog.content).blocks[1].text.slice(0, 150) || JSON.parse(singleBlog.content).blocks[3].text.slice(0, 150)
                                                    }...
                                                </p>
                                                <Button onClick={() => { redirectToReadPostPage(singleBlog.title) }}>Read More</Button>
                                            </Col>
                                            <Col span={3}>
                                                <p className="blogDate">
                                                    {`${new Date(singleBlog.created).getDate()} ${months[new Date(singleBlog.created).getMonth()]}, ${new Date(singleBlog.created).getFullYear()}`}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            })
                        }
                    </div>
            }
            <br />
            <Footer />
        </>
    )
}

export default PostsPage;