// Import antd components
import { Col } from "antd";

const Footer = () => {
    return (
        <>
            <Col className = "footer">
                <p className = "footerText">
                Diamond Hands LLP is a SEBI Registered Stockbroker | INZ000307157 | Registered Address: 2007-08, Cyber One, Sector 30A, Vashi, Navi Mumbai, Maharashtra 400703, India | LLP Identification no: ABA-0634
                </p>
            </Col>
        </>
    )
}

export default Footer;