import { useState, useEffect } from "react";

// Routing
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import Page Components
import {
	HomePage,
	CreatePost,
	PostsList,
	PostView
} from "Pages";

// Import Call API
import CallAPI from "CallAPI";

import { browserName } from "react-device-detect";

// Import Base URL
import { BASE_URL } from "constants";

const App = () => {
	const [userId, setUserId] = useState(localStorage.getItem('userId') ? localStorage.getItem('userId') : "");

	let source = new URLSearchParams(window.location.search).get("src");
	let deviceType = (window.innerWidth < 768) ? "mobile" : (window.innerWidth < 992) ? "tablet" : (window.innerWidth > 992) ? "desktop" : "";

	const createUserEntry = async () => {
		let data = {
			device: deviceType,
			browser: browserName,
			source: source,
			userId: userId
		}

		if (userId !== undefined) {
			setUserId(userId);
		}

		const response = await CallAPI("POST", BASE_URL + "/users/createOrUpdateUser", {}, data);
		if (response.result && response.result.userId) {
			localStorage.setItem('userId', response.result.userId);
			setUserId(response.result.userId);
		}
	}

	useEffect(() => {
		createUserEntry();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<HomePage />} />
					<Route path="/:password" element={<CreatePost />} />
					<Route path="/:password/:posttitle" element={<CreatePost />} />
					<Route path="posts" element={<PostsList />} />
					<Route path="posts/:posttitle" element={<PostView />} />
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App;