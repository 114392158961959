// Import components

import {
	Header,
	Footer
} from "Layouts";

import { Home } from "Components";

// Import styles
import './homePage.css';
import { useEffect } from "react";

const HomePage = () => {
	useEffect(() => {

		const ogTags = {
			"og:title": "Get your stock market queries answered by experts for free",
			"og:url": "https://021.trade/",
			"og:image": "https://021.trade/assets/icons/og-image.jpg",
			"og:type": "website"
		}

		const metaTags = document.head.querySelectorAll('meta');
		metaTags.forEach((tag) => {
			document.head.removeChild(tag);
			const property = tag.getAttribute('property');

			if (ogTags[property]) {
				tag.setAttribute("content", ogTags[property]);
			}

			document.head.insertBefore(tag, document.head.firstChild);
		})
	}, []);

	return (
		<div className='app'>
			<Header />
			<Home />
			<Footer />
		</div>

	);
}

export default HomePage;