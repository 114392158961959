// Import AntD icons
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loader = () => {
    const antIcon = (
        <LoadingOutlined
            style={{
                textAlign: "center",
                fontSize: 200,
            }}
            spin
        />
    );

    return (
        <div className = "loaderElement">
            <Spin indicator={antIcon} />
        </div>
    )
}

export default Loader;