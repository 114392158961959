// Import components
import ContactForm from "./ContactForm";

// Import antd components
import { Popover } from "antd";

const CardDescription = () => {
    const content = (
        <div>
            <b>For instance, you can ask us:</b>
            <p>• What is a call option?</p>
            <p>• How is the nifty index calculated?</p>
            <p>• My demat is frozen, please help!</p>
        </div>
    )

    return (
        <div className = "mainCardContainer">
            <div className = "mainCard">
                <p className = "cardHeader">
                    In the meantime, if you have any <Popover content = {content} trigger = "hover"><span className = "questionsPopup">questions</span></Popover> about the stock market, our experts are here to help.
                </p>
                <p className = "cardContent">
                    Enter your contact details to line up a call or chat with us.
                </p>
            </div>
            <br/>
            <ContactForm/>
        </div>
    )
}

export default CardDescription;